import React, { useEffect, useState } from "react";
import Header from "../Component/Header";
import Sidebar from "../Component/Sidebar";
import QRCode from "react-qr-code";
import { useDispatch, useSelector } from "react-redux";
import { FaSortDown, FaSortUp } from "react-icons/fa";
import "../Css/Stepper.css";
import { Link } from "react-router-dom";
import { transferToken, withdrawDetails, withdrawList, withdrawOtpSend } from "../utils/apis";
import { toast } from "react-toastify";
import { cutAfterDecimal, fetchWalletHandler } from "../utils/actionHandler";
import OTPInput from "react-otp-input";

const coins = [
  {
    symbol: "INRx",
    name: "Indian stable currency",
    icon: "images/favicon.svg",
  },
];

const networks = [
  // {
  //   nativeToken: "ETH (ERC20)",
  //   chain: "Ethereum",
  // },
  {
    nativeToken: "BNB (BEP20)",
    chain: "Binance Smart Chain",
  },
  // {
  //   nativeToken: "TRX (TRC20)",
  //   chain: "Tron",
  // },
];

const Withdraw = () => {
  const [coinSelected, setCoinSelected] = useState(false);
  const [networkSelected, setNetworkSelected] = useState(false);
  const { user, sessionId, userdata } = useSelector((state) => state.user);
  const { assets } = useSelector((state) => state.wallet);
  const [withdrawlist, setWithdrawList] = useState([]);
  const [amount, setAmount] = useState("");
  const [reciever, setReciever] = useState("");
  const [coinindex, setCoinindex] = useState(-1);
  const [chainindex, setChainindex] = useState(-1);
  const [mobileOtp, setMobileOtp] = useState("");
  const [emailOtp, setEmailOtp] = useState("");

  const [loading, setLoading] = useState(false);
  const [withdrawDetailsData, setWithdrawDetails] = useState({})
  const token = coins[coinindex]?.symbol;
  const chain = networks[chainindex]?.chain != "Tron" ? "Ethereum" : "Tron";
  const asset = assets.find((it) => it?.symbol == token?.toUpperCase());
  console.log(asset,"asset")
  const dispatch = useDispatch();

  const handleCoinSelect = (cindex) => {
    setCoinSelected(true);
    setCoinindex(cindex);
  };

  const handleNetworkSelect = (nindex) => {
    setNetworkSelected(true);
    setChainindex(nindex);
  };

  function withdrawOtp(){
    const data = {
      email: userdata.email,
      mobile: userdata.mobile,
      tokenId: sessionId,
      amount: amount,
      coin: token,
      type: "CRYPTO"

    };

    if (userdata?.mobile && userdata?.email && sessionId && amount && token){
    setLoading(true);
    withdrawOtpSend(data)
    .then((res) => {
      if (res.status == 200) {
        const modal = new window.bootstrap.Modal(document.getElementById('verification_modal'));
        modal.show();
        toast.success(res.message);
      } else {
        console.log(res);
        toast.error(res?.message);
      }
      setLoading(false);
    })
    .catch((e) => {
      setLoading(false);
      toast.error("Something went wrong!");
      console.log(e, "Error in withdraw():Withdraw.jsx");
    });
  } else{
      toast.error("Something went wrong!")
  }
  }

  function withdraw() {
    const data = {
      email: userdata.email,
      mobile: userdata.mobile,
      amount: amount,
      reciever: reciever,
      blockchain: chain,
      coin: token,
      tokenId: sessionId,
      emailOtp: emailOtp,
      mobileOtp:mobileOtp
    };
    if(loading){
      toast.info("Please wait for pending request")
      return
    }
    // console.log(data, "data withdraw");
    setLoading(true);
    transferToken(data)
      .then((res) => {
        if (res.status == 200) {
          setAmount("");
          setEmailOtp()
          setMobileOtp()
          const modal = document.getElementById(`verification-modal`);
          modal.click();
          toast.success("Withdraw Successful!");
          fetchWalletHandler(userdata?.mobile, sessionId, dispatch);
        } else {
          console.log(res);
          toast.error(res?.message);
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        toast.error("Something went wrong!");
        console.log(e, "Error in withdraw():Withdraw.jsx");
      });
  }

  function handleClose(){
    setAmount("");
    setEmailOtp()
    setMobileOtp()
  }

  useEffect(() => {
    if (userdata?.mobile && sessionId) {
      withdrawList(userdata.mobile)
        .then((res) => {
          if (res?.status == 200) {
            setWithdrawList(res?.data);
          }
        })
        .catch((e) => {
          console.log(e, "Error in setWithdrawList:withdraw page");
        });
    }
  }, [userdata, sessionId]);

  useEffect(()=>{
    withdrawDetails().then((res) => {
      if (res?.status == 200) {
        setWithdrawDetails(res?.data);
      }
    })
    .catch((e) => {
      console.log(e, "Error in setWithdrawList:withdraw page");
    });
  },[])
  

  return (
    <>
      <Header />
      <Sidebar />
      <main className="mt-5 pt-5 deposit_main">
        <div className="container-fluid">
          <div className="d-flex justify-content-between mb-4">
            <div>
              <h1 className="heading">Withdrwal</h1>
            </div>
            <div>
              <Link to="/withdraw_fiat" className="custom_btn_outline fs-14">
                Withdrwal INR
                <i className="fa-solid fa-arrow-right-long ms-1"></i>
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-5 col-lg-12 col-md-6 col-sm-8 mb-4">
              <div className="accordion verification_accrodion">
                <div className="timeline_container">
                  <div className="hide_on_mobile">
                    <div
                      className={
                        coinSelected
                          ? "accor_timeline_no_check"
                          : "accor_timeline_no"
                      }
                    >
                      1
                    </div>
                    <div
                      className={
                        coinSelected
                          ? "accor_timeline_line_check"
                          : "accor_timeline_line"
                      }
                    ></div>
                  </div>
                  <div className="flex-grow-1">
                    <div className="accordion-item border-0 mb-4">
                      <h2 className="" id="headingOne">
                        <button
                          className="border-0 bg-transparent text-accordion fw-bold h6 mb-0"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          Select Coin
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="headingOne"
                      >
                        <div className="secondaryForm">
                          <div className="">
                            <div className="select_box asset_dropdown_wrapper">
                              <div
                                className="asset_dropdown dropdown-toggle"
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                               {coins[coinindex]?.symbol ? <div className="d-flex align-items-center">
                                  <img
                                    src={coins[coinindex]?.icon}
                                    alt="coin symbol"
                                    className="coin_img me-2"
                                  />
                                  <div className="product_name">
                                    {coins[coinindex]?.symbol} &nbsp;
                                    <span className="unit text-muted">
                                      {coins[coinindex]?.name}
                                    </span>
                                  </div>
                                </div>:
                                <button
                                  className="border-0 bg-transparent text-accordion fs-12 mb-0"
                                  type="button"
                                >
                                  Select Coin
                                </button>
                              }
                                <div>
                                  <img
                                    src="https://assets.staticimg.com/kucoin-main-web/2.0.20/svg/arrow.267b17d2.svg"
                                    className="arrow_img"
                                    alt="arrow-icon"
                                  />
                                </div>
                              </div>
                              <ul
                                className="dropdown-menu asset_dropdown_menu"
                                aria-labelledby="dropdownMenuButton1"
                              >
                                <div>
                                  <div className="custom_scrollbar height250">
                                    {coins.map((item, i) => {
                                      return (
                                        <div
                                          className="select_options"
                                          onClick={() => handleCoinSelect(i)}
                                          key={"select_options_withdraw" + i}
                                        >
                                          <div>
                                            <img
                                              src={item?.icon}
                                              alt="coin symbol"
                                              className="market_coin_img"
                                            />
                                          </div>
                                          <div className="product_name">
                                            <div className="mb-0 fw-bold">
                                              {item?.symbol}
                                            </div>
                                            <div className="text-muted fs-12">
                                              {item?.symbol}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                    {/* <div
                                      className="select_options"
                                      onClick={handleCoinSelect}
                                    >
                                      <div>
                                        <img
                                          src="images/favicon.svg"
                                          alt="coin symbol"
                                          className="market_coin_img"
                                        />
                                      </div>
                                      <div className="product_name">
                                        <div className="mb-0 fw-bold">INRx</div>
                                        <div className="text-muted fs-12">
                                          Indian stable assets
                                        </div>
                                      </div>
                                    </div> */}
                                  </div>
                                </div>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="timeline_container">
                  <div className="hide_on_mobile">
                    <div
                      className={
                        networkSelected
                          ? "accor_timeline_no_check"
                          : "accor_timeline_no"
                      }
                    >
                      2
                    </div>
                    <div
                      className={
                        networkSelected
                          ? "accor_timeline_line_check"
                          : "accor_timeline_line"
                      }
                    ></div>
                  </div>
                  <div className="accordion-item border-0">
                    <h2 className="" id="headingTwo">
                      <button
                        className={`border-0 bg-transparent text-accordion fw-bold h6 mb-0 ${
                          coinSelected ? "" : "collapsed"
                        }`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                        disabled={!coinSelected}
                      >
                        Select Network
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className={`accordion-collapse collapse ${
                        coinSelected ? "show" : ""
                      }`}
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="secondaryForm">
                        <div className="mb-5">
                          <div className="asset_dropdown_wrapper select_box mb-4">
                            <div
                              className="asset_dropdown-- asset_dropdown dropdown-toggle"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                             {networks[chainindex]?.chain ? <div className="product_name">
                                {networks[chainindex]?.nativeToken}{" "}
                                {networks[chainindex]?.chain}
                              </div>:
                                <button
                                  className="border-0 bg-transparent text-accordion fw-bold fs-12 mb-0"
                                  type="button"
                              
                                >
                                  Select Network
                                </button>
                              }
                              <div className="text-end">
                                <img
                                  src="https://assets.staticimg.com/kucoin-main-web/2.0.20/svg/arrow.267b17d2.svg"
                                  className="arrow_img"
                                  alt="arrow-icon"
                                />
                              </div>
                            </div>
                            <ul
                              className="dropdown-menu asset_dropdown_menu"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <div>
                                {/* <div className="alert alert-info">
                                  <div className="d-flex">
                                    <div>
                                      <i className="fa fa-exclamation-circle me-2 text-green"></i>
                                    </div>
                                    <div className="text-lgray mob-font">
                                      Ensure that the selected deposit network
                                      is the same as the withdrawal network.
                                      Otherwise, your assets could be lost.
                                    </div>
                                  </div>
                                </div> */}
                                <div className="custom_scrollbar height275">
                                  {networks.map((item, i) => {
                                    return (
                                      <div
                                        className="select_options"
                                        onClick={() => handleNetworkSelect(i)}
                                        key={i + "networks-list"}
                                      >
                                        <div className="product_name">
                                          <div className="mb-0 fw-bold">
                                            {item?.nativeToken}
                                          </div>
                                          <div className="text-muted fs-12">
                                            {item?.chain}
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </ul>
                          </div>
                          <div>
                            <label className="text-lgray mb-1">
                              Withdrawal Amount
                            </label>

                            <div className="input-group">
                              <input
                                type="number"
                                className="form-control custombg"
                                placeholder="Enter Withdrawal Amount"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                              />
                            </div>
                            <div className="d-flex justify-content-between fs-14 mt-2">
                              <div className="text-green fw-bold">
                                Wallet Balance{" "}
                              </div>
                              <div className="fw-bold">
                                {asset?.available
                                  ? cutAfterDecimal(asset?.available, 4)
                                  : "0.00"}
                              </div>
                            </div>
                            <div className="d-flex justify-content-between mt-2">
                              <div className="text-muted">
                                Minimum Withdrawal Amount
                              </div>
                              <div className="fw-bold">{withdrawDetailsData?.minimum_withdraw_inrx} INRx</div>
                            </div>

                            <div className="d-flex justify-content-between">
                              <div className="text-muted">
                                Withdrawal Fee
                              </div>
                              <div className="fw-bold">{withdrawDetailsData?.withdraw_inrx_fee}  INRx</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="timeline_container">
                  <div className="hide_on_mobile">
                    <div
                      className={
                        networkSelected
                          ? "accor_timeline_no_check"
                          : "accor_timeline_no"
                      }
                    >
                      <i className="fa-solid">3</i>
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <h2 className="" id="headingThree">
                      <button
                        className={`border-0 bg-transparent text-accordion fw-bold h6 mb-0 ${
                          networkSelected ? "" : "collapsed"
                        }`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                        disabled={!networkSelected}
                      >
                        Withdraw Address
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className={`accordion-collapse collapse ${
                        networkSelected ? "show" : ""
                      }`}
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="secondaryForm">
                        <div className="mb-3">
                          <input
                            type="text"
                            className="form-control custombg"
                            placeholder="Enter Wallet Address"
                            value={reciever}
                            onChange={(e) => setReciever(e.target.value)}
                          />
                        </div>
                         {/* OTP section conditionally rendered based on reciever being filled */}
                      {/* {reciever && (
                        <div className="mb-3">
                          <p className="text-center text-lgray mb-0">Enter G-Auth OTP</p>
                          <OTPInput
                              value={otp}
                              onChange={(e) => setotp(e.target.value)}
                              inputMode="numeric"
                              numInputs={6}
                              renderSeparator={
                                <span className="text-secondary mx-1"></span>
                              }
                              renderInput={(props) => (
                                <input
                                  {...props}
                                  className="form-control custombg"
                                  style={{ width: "40px" }}
                                  inputMode="numeric" // Ensures numeric keypad on mobile
                                  pattern="[0-9]*" // Enforces numeric input
                                  type="tel"
                                  onKeyPress={(e) => {
                                    if (!/[0-9]/.test(e.key)) {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                              )}
                              containerStyle={{
                                justifyContent: "center",
                                paddingBlock: "2%",
                              }}
                            />
                        </div>
                      )} */}
                      </div>
                      <div className="d-grid mb-3">
                        <button
                          
                          className="custom_btn"
                          type="button"
                          // data-bs-toggle="modal"
                          // data-bs-target="#verification_modal"
                          onClick={withdrawOtp}
                          disabled={loading}

                        >
                          {loading ? (
                            <div
                              className="spinner-border text-black load-icon mx-1"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          ) : null}
                          Withdraw
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-12 col-md-1 col-sm-1"></div>
            <div className="col-xl-4 col-lg-12 col-md-5 col-sm-8">
             <div className="card">
              <div className="card-body">
              <h5 className="">FAQs</h5>
              <ul className="faqList">
              <hr/>

                <li>
                  <a href="#"> How to withdraw INRx? (Video) </a>
                </li>
                <hr/>
                <li>
                  <a href="#"> How to Find My Transaction ID (TxID)? </a>
                </li>
                <hr/>

                <li>
                  <a href="#"> How to Recover My BEP-20 Tokens? </a>
                </li>
                <hr/>

                <li>
                  <a href="#"> Deposit & Withdrawal Status query? </a>
                </li>
              </ul>
              </div>
             </div>
            </div>
          </div>
          <div className="mt-5">
            <div className="col-lg-12">
              <div className="flex_row mb-2">
                <div className="">
                  <div className="">
                    <h6 className="heading"> Withdraw History </h6>
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-body">
                <div className="table-responsive custom_table">
                  <table className="table table-borderless">
                    <thead>
                      <tr>
                        <th className="text-end">
                          <div className="d-flex align-items-center">
                            <span className="">Hash</span>
                            <div className="mx-1">
                              <div className="position-relative">
                                <FaSortUp className="sorting_btn" />
                              </div>

                              <div className="position-relative">
                                <FaSortDown className="sorting_btn" />
                              </div>
                            </div>
                          </div>
                        </th>
                        <th className="text-end">
                          <div className="d-flex align-items-center">
                            <span className="">Symbol</span>
                            <div className="mx-1">
                              <div className="position-relative">
                                <FaSortUp className="sorting_btn" />
                              </div>

                              <div className="position-relative">
                                <FaSortDown className="sorting_btn" />
                              </div>
                            </div>
                          </div>
                        </th>
                        <th className="text-end">
                          <div className="d-flex align-items-center justify-content-end">
                            <div className="">Amount</div>
                            <div className="mx-1">
                              <div className="position-relative">
                                <FaSortUp className="sorting_btn" />
                              </div>

                              <div className="position-relative">
                                <FaSortDown className="sorting_btn" />
                              </div>
                            </div>
                          </div>
                        </th>
                        <th className="text-end">
                          <div className="d-flex align-items-center justify-content-end">
                            <span className="">Address</span>
                            <div className="mx-1">
                              <div className="position-relative">
                                <FaSortUp className="sorting_btn" />
                              </div>

                              <div className="position-relative">
                                <FaSortDown className="sorting_btn" />
                              </div>
                            </div>
                          </div>
                        </th>
                        <th className="text-end">
                          <div className="d-flex align-items-center justify-content-end">
                            <span className=""> Status</span>
                            <div className="mx-1">
                              <div className="position-relative">
                                <FaSortUp className="sorting_btn" />
                              </div>

                              <div className="position-relative">
                                <FaSortDown className="sorting_btn" />
                              </div>
                            </div>
                          </div>
                        </th>
                        <th className="text-end">
                          <div className="d-flex align-items-center justify-content-end">
                            <span className="">Time</span>
                            <div className="mx-1">
                              <div className="position-relative">
                                <FaSortUp className="sorting_btn" />
                              </div>

                              <div className="position-relative">
                                <FaSortDown className="sorting_btn" />
                              </div>
                            </div>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {withdrawlist?.length == 0 || !withdrawlist ? (
                        <tr>
                          <td colSpan={6} className="text-center">
                            <img
                              src="images/no-data.png"
                              className="no-data-found"
                            />
                          </td>
                        </tr>
                      ) : (
                        withdrawlist.map((item, i) => {
                          return (
                            <tr key={i + "DepsoitList"}>
                              <td className="text-start">
                                {item?.hash?.length > 25 ? (
                                  <a
                                    className="text-success"
                                    href={
                                      "https://testnet.bscscan.com/tx/" +
                                      item?.hash
                                    }
                                    target="_blank"
                                  >
                                    {item?.hash?.slice(0, 10) +
                                      "..." +
                                      item?.hash?.slice(50)}
                                  </a>
                                ) : (
                                  <span className="text-color">
                                    {item?.hash}
                                  </span>
                                )}
                              </td>
                              <td className="text-start">{item?.symbol}</td>
                              <td className="text-end">
                                {cutAfterDecimal(item?.amount, 4)}
                              </td>
                              <td className="text-end">{item?.reciever}</td>
                              <td
                                className={`text-end ${
                                  item?.success ? "text-success" : "text-danger"
                                }`}
                              >
                                {item?.success ? "success" : "failed"}
                              </td>
                              <td className="text-end">
                                {new Date(item?.createdAt).toLocaleString()}
                              </td>
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>


      <div
        class="modal fade"
        id="verification_modal"
        tabindex="-1"
        aria-labelledby="verification"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5">
                Verification
              </h1>
              <button
                id="verification-modal"
                type="button"
                class="bg-transparent fs-4 border-0 text-color"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              >
                X
              </button>
            </div>
            <div className="modal-body">
            <div className="mb-3">
              <p className="text-center mb-0">Enter 6-digit OTP sent to your registered email id</p>
              <OTPInput
                  value={emailOtp}
                  onChange={setEmailOtp}
                  inputMode="numeric"
                  numInputs={6}
                  renderSeparator={
                    <span className="text-secondary mx-1"></span>
                  }
                  renderInput={(props) => (
                    <input
                      {...props}
                      className="form-control"
                      style={{ width: "40px" }}
                      inputMode="numeric" // Ensures numeric keypad on mobile
                      pattern="[0-9]*" // Enforces numeric input
                      type="tel"
                      onKeyPress={(e) => {
                        if (!/[0-9]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  )}
                  containerStyle={{
                    justifyContent: "center",
                    paddingBlock: "2%",
                  }}
                />
            </div>

<hr/>

            <div className="mb-3">
              <p className="text-center mb-0">Enter 6-digit OTP sent to your registered mobile number</p>
              <OTPInput
                  value={mobileOtp}
                  onChange={setMobileOtp}
                  inputMode="numeric"
                  numInputs={6}
                  renderSeparator={
                    <span className="text-secondary mx-1"></span>
                  }
                  renderInput={(props) => (
                    <input
                      {...props}
                      className="form-control"
                      style={{ width: "40px" }}
                      inputMode="numeric" // Ensures numeric keypad on mobile
                      pattern="[0-9]*" // Enforces numeric input
                      type="tel"
                      onKeyPress={(e) => {
                        if (!/[0-9]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  )}
                  containerStyle={{
                    justifyContent: "center",
                    paddingBlock: "2%",
                  }}
                />
            </div>
            <div className="w-100 mb-3">
              <button
                className="custom_btn w-100"
                type="button"
                onClick={withdraw}
                disabled={loading}
              >
                {loading ? (
                  <div
                    className="spinner-border text-black load-icon mx-1"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : null}
                Withdraw
              </button>
            </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Withdraw;
