import axios from "axios";

const localip = "localhost";
const serverip = "64.227.149.24";
// const AUTH_API_URL = `http://${localip}:2222/api/auth`;
// const WALLET_API_URL = `http://${localip}:2222/api/wallet`;
// const STAKE_API_URL = `http://${localip}:2222/api/stake`;

export const newurl = "https://backoffice.inrx.io/api";
const AUTH_API_URL = `${newurl}/auth`;
const WALLET_API_URL = `${newurl}/wallet`;
const STAKE_API_URL = `${newurl}/stake`;

export const aprSet = 48 ;

export function getLoginInfo(loginId, action, ref_code) {
  return fetch(AUTH_API_URL + "/qrlogin", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "allow-access-control-origin": "*",
    },
    body: JSON.stringify({
      loginId: loginId,
      action: action,
      ref_code: ref_code ? ref_code : "",
    }),
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e, "Error in getLoginInfo()::apis.tsx");
    });
}

export function googleSignIn(credential, reffarrerId) {
  return fetch(AUTH_API_URL + "/google-signin", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "allow-access-control-origin": "*",
    },
    body: JSON.stringify({
      secret_token: credential,
      referrerId: reffarrerId,
    }),
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e, "Error in googleSignIn()::apis.tsx");
    });
}

export function loginWithEmail(email, password, referrerId, otp) {
  return fetch(AUTH_API_URL + "/email-signin", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "allow-access-control-origin": "*",
    },
    body: JSON.stringify({
      email: email,
      password: password,
      referrerId: referrerId,
      otp: otp,
    }),
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e, "Error in loginWithEmail()::apis.tsx");
    });
}

export function loginWithMobile(mobile, password, referrerId, otp) {
  return fetch(AUTH_API_URL + "/mobile-signin", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "allow-access-control-origin": "*",
    },
    body: JSON.stringify({
      mobile: mobile,
      password: password,
      referrerId: referrerId,
      otp: otp,
    }),
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e, "Error in loginWithEmail()::apis.tsx");
    });
}

export function mobileOTPVerify(otp,mobile) {
  return fetch(AUTH_API_URL + "/mobile-OTPVerify", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "allow-access-control-origin": "*",
    },
    body: JSON.stringify({
      mobile: mobile,
      otp: otp,
    }),
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e, "Error in loginWithEmail()::apis.tsx");
    });
}

export function emailOTPVerify(otp,email) {
  return fetch(AUTH_API_URL + "/email-OTPVerify", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "allow-access-control-origin": "*",
    },
    body: JSON.stringify({
      email: email,
      otp: otp,
    }),
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e, "Error in loginWithEmail()::apis.tsx");
    });
}

export function forgetEmailOrMobile(emailandmobile) {
  return fetch(AUTH_API_URL + "/forget-password", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "allow-access-control-origin": "*",
    },
    body: JSON.stringify({
      mobile: emailandmobile?.indexOf("@") == -1 ? emailandmobile : null,
      email: emailandmobile?.indexOf("@") > -1 ? emailandmobile : null,
    }),
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e, "Error in loginWithEmail()::apis.tsx");
    });
}

export function forgetOtpMatch(emailandmobile, otp, password,type) {
  return fetch(AUTH_API_URL + "/forget-otp-match", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "allow-access-control-origin": "*",
    },
    body: JSON.stringify({
      mobile: emailandmobile?.indexOf("@") == -1 ? emailandmobile : null,
      email: emailandmobile?.indexOf("@") > -1 ? emailandmobile : null,
      newPassword:password,
      otp:otp,
      type:type
    }),
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e, "Error in loginWithEmail()::apis.tsx");
    });
}

export function logOut(mobile, userId, sessionId) {
  return fetch(AUTH_API_URL + "/logout-panel", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
    },
    body: JSON.stringify({
      userId: userId,
      tokenId: sessionId,
      mobile: mobile?.indexOf("@") == -1 ? mobile : "",
      email: mobile?.indexOf("@") > -1 ? mobile : "",
    }),
  })
    .then((res) => res.json())
    .catch((e) => console.log(e, "Error in logOut()::apis.js"));
}

export function fetchUser(mobile, tokenId) {
  return fetch(AUTH_API_URL + "/fetch-user", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "allow-access-control-origin": "*",
    },
    body: JSON.stringify({
      mobile: mobile?.indexOf("@") == -1 ? mobile : "",
      tokenId,
      email: mobile?.indexOf("@") > -1 ? mobile : "",
    }),
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e, "Error in fetchUser()::apis.tsx");
    });
}

export function setUserEmail(email, mobile, tokenId) {
  return fetch(AUTH_API_URL + "/set-email", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "allow-access-control-origin": "*",
    },
    body: JSON.stringify({ email: email, tokenId: tokenId, mobile }),
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e, "Error in setEmail()::apis.tsx");
    });
}

export function setEmailOrMobile(mobile, email, tokenId, otp) {
  return fetch(AUTH_API_URL + "/set-email-or-mobile", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "allow-access-control-origin": "*",
    },
    body: JSON.stringify({ email: email, tokenId: tokenId, mobile:mobile, otp:otp }),
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e, "Error in setEmail()::apis.tsx");
    });
}

export function setUserPassword(mobile, password, confirmPassword, tokenId) {
  return fetch(AUTH_API_URL + "/set-password", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "allow-access-control-origin": "*",
    },
    body: JSON.stringify({
      mobile: mobile,
      password,
      confirmPassword,
      tokenId,
    }),
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e, "Error in setPassword()::apis.tsx");
    });
}

export function changeUserPassword(useremailOrMobile,tokenId, otp, newPassword,confirmPassword, type) {
  return fetch(AUTH_API_URL + "/change-password", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "allow-access-control-origin": "*",
    },
    body: JSON.stringify({
      useremailOrMobile, tokenId, newPassword, confirmPassword, otp, type
    }),
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e, "Error in changeUserPassword()::apis.tsx");
    });
}

export function enable2FA(mobile, tokenId, action, otp) {
  return fetch(AUTH_API_URL + "/enable-2fa", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "allow-access-control-origin": "*",
    },
    body: JSON.stringify({ mobile, tokenId, action, otp }),
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e, "Error in enable2FA()::apis.tsx");
    });
}

export function fetchWallet(mobile,tokenId) {
  return fetch(WALLET_API_URL + "/fetch-wallet", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "allow-access-control-origin": "*",
    },
    body: JSON.stringify({
      mobile: mobile?.indexOf("@") == -1 ? mobile : "",
      email: mobile?.indexOf("@") > -1 ? mobile : "",
      tokenId:tokenId,
    }),
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e, "Error in fetchWallet()::apis.tsx");
    });
}

export function updateBalance(mobile) {
  return fetch(WALLET_API_URL + "/update-wallet", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "allow-access-control-origin": "*",
    },
    body: JSON.stringify({ mobile: mobile }),
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e, "Error in updateBalance()::apis.tsx");
    });
}

export function withdrawDetails(){
  return fetch(WALLET_API_URL + "/withdraw-details", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "allow-access-control-origin": "*",
    },
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e, "Error in withdrawDetails()::apis.tsx");
    });
}

export function withdrawOtpSend({email, mobile, tokenId, amount, coin, type}) {
  return fetch(WALLET_API_URL + "/withdraw-otp", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "allow-access-control-origin": "*",
    },
    body: JSON.stringify({email, mobile, tokenId, amount, coin, type }),
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e, "Error in withdrawOtpSend()::apis.tsx");
    });
}

export function transferToken({
  email,
  mobile,
  amount,
  reciever,
  blockchain,
  coin,
  tokenId,
  emailOtp,
  mobileOtp
}) {
  return fetch(WALLET_API_URL + "/transfer-token", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "allow-access-control-origin": "*",
    },
    body: JSON.stringify({
      email,
      mobile,
      amount,
      reciever,
      blockchain,
      coin,
      tokenId,
      emailOtp,
      mobileOtp
    }),
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e, "Error in transferToken()::apis.tsx");
    });
}

export function withdrawList(mobile) {
  return fetch(WALLET_API_URL + "/withdraw-list", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "allow-access-control-origin": "*",
    },
    body: JSON.stringify({ mobile: mobile }),
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e, "Error in withdrawList()::apis.tsx");
    });
}

export function stakeINRXToken(data) {
  return fetch(STAKE_API_URL + "/stake-token", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "allow-access-control-origin": "*",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e, "Error in stakeToken()::apis.tsx");
    });
}

export function getStakeData(data) {
  return fetch(STAKE_API_URL + "/get-all-stakes", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "allow-access-control-origin": "*",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e, "Error in getStakeData()::apis.tsx");
    });
}

export function getStakeRefData(data) {
  return fetch(STAKE_API_URL + "/get-stakes-ref", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "allow-access-control-origin": "*",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e, "Error in getStakeRefData()::apis.tsx");
    });
}

export function convertToken(data) {
  return fetch(WALLET_API_URL + "/convert-token", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "allow-access-control-origin": "*",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e, "Error in convertToken()::apis.tsx");
    });
}

export function convertHistory(data) {
  return fetch(WALLET_API_URL + "/convert-list", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "allow-access-control-origin": "*",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e, "Error in swapHistory()::apis.tsx");
    });
}

export function claimReward(data) {
  return fetch(STAKE_API_URL + "/claim-token", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "allow-access-control-origin": "*",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e, "Error in claimReward()::apis.tsx");
    });
}
export function claimRewardRef(data) {
  return fetch(STAKE_API_URL + "/claim-token-ref", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "allow-access-control-origin": "*",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e, "Error in claimReward()::apis.tsx");
    });
}

export function claimHistory(data) {
  return fetch(STAKE_API_URL + "/claim-list", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "allow-access-control-origin": "*",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e, "Error in claimHistory()::apis.tsx");
    });
}

export function depositHistory(mobile, tokenId, type) {
  return fetch(WALLET_API_URL + "/deposit-list", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "allow-access-control-origin": "*",
    },
    body: JSON.stringify({ mobile: mobile, tokenId: tokenId, type:type }),
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e, "Error in depositHistory()::apis.tsx");
    });
}

export function depositINR(mobile, tokenId, amount) {
  return fetch(WALLET_API_URL + "/deposit-inr", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "allow-access-control-origin": "*",
    },
    body: JSON.stringify({
      mobile: mobile,
      tokenId: tokenId,
      amount: Number(amount),
    }),
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e, "Error in depositHistory()::apis.tsx");
    });
}

export function depositINRStatus(mobile, tokenId, amount, txid) {
  return fetch(WALLET_API_URL + "/deposit-inr-status", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "allow-access-control-origin": "*",
    },
    body: JSON.stringify({
      mobile: mobile,
      tokenId: tokenId,
      amount: Number(amount),
      txid: txid,
    }),
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e, "Error in depositStatus()::apis.tsx");
    });
}

export function withdrawINRBankDetails({email, mobile, tokenId}) {
  return fetch(WALLET_API_URL + "/withdraw-inr-bank-details", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "allow-access-control-origin": "*",
    },
    body: JSON.stringify({email, mobile, tokenId}),
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e, "Error in depositHistory()::apis.tsx");
    });
}

export function withdrawINR({email, mobile, tokenId, amount, bankId, emailOtp, mobileOtp}) {
  return fetch(WALLET_API_URL + "/withdraw-inr", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "allow-access-control-origin": "*",
    },
    body: JSON.stringify({
      emailOtp:emailOtp, 
      mobileOtp:mobileOtp,
      email: email,
      mobile: mobile,
      tokenId: tokenId,
      amount: Number(amount),
      bankId: bankId,
    }),
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e, "Error in depositHistory()::apis.tsx");
    });
}

export function loginHistory(mobile, tokenId) {
  return fetch(AUTH_API_URL + "/login-history", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "allow-access-control-origin": "*",
    },
    body: JSON.stringify({
      mobile: mobile,
      userId: tokenId,
    }),
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e, "Error in loginHistory()::apis.tsx");
    });
}

export function deleteLoginHistory(mobile, userId, tokenId, loginId) {
  return fetch(AUTH_API_URL + "/delete-login-history", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "allow-access-control-origin": "*",
    },
    body: JSON.stringify({
      mobile: mobile,
      userId: userId,
      tokenId,
      loginId,
    }),
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e, "Error in loginHistory()::apis.tsx");
    });
}

export function activityHistory(mobile, tokenId) {
  return fetch(AUTH_API_URL + "/user-activity", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "allow-access-control-origin": "*",
    },
    body: JSON.stringify({
      mobile: mobile,
      tokenId,
    }),
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e, "Error in activityHistory()::apis.tsx");
    });
}

export function goPremium(mobile, tokenId) {
  return fetch(AUTH_API_URL + "/go-premium", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "allow-access-control-origin": "*",
    },
    body: JSON.stringify({
      mobile: mobile,
      tokenId,
    }),
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e, "Error in goPremium()::apis.tsx");
    });
}

export function getReferrralsList(mobile, tokenId) {
  return fetch(AUTH_API_URL + "/referral-list", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "allow-access-control-origin": "*",
    },
    body: JSON.stringify({
      mobile: mobile,
      tokenId,
      page: 1,
      limit: 10,
    }),
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e, "Error in goPremium()::apis.tsx");
    });
}

export function raiseTicket(mobile, sessionId, subject, message, queryfile) {
  const formData = new FormData();
  if (queryfile) {
    formData.append("query", queryfile, queryfile.name);
  }
  formData.append("mobile", mobile);
  formData.append("tokenId", sessionId);
  formData.append("subject", subject);
  formData.append("message", message);

  return axios
    .post(AUTH_API_URL + "/raise-ticket", formData, {})
    .then((res) => res)
    .catch((e) => {
      console.log(e);
    });
}

export function verifyUserKYC(email, tokenId, doc_number, doc_type, country, state,mobile,images) {
  const formData = new FormData();
  if (images) {
    formData.append("kyc_doc", images[0], images[0].name);
    formData.append("kyc_doc", images[1], images[1].name);
  }
  formData.append("email", email);
  formData.append("tokenId", tokenId);
  formData.append("doc_number", doc_number);
  formData.append("doc_type", doc_type);
  formData.append("country", country);
  formData.append("state", state);
  formData.append("mobile", mobile);

  return axios
    .post(AUTH_API_URL + "/verify-kyc", formData, {})
    .then((res) => res)
    .catch((e) => {
      console.log(e);
    });
}

export function nScreenShot(event, email, tokenId, file, amount, transaction_no) {
  event.preventDefault();
  
  const formData = new FormData();
  formData.append("deposit", file, file.name);
  formData.append("email", email);
  formData.append("tokenId", tokenId);
  formData.append("amount", amount);
  formData.append("transaction_no", transaction_no); // Corrected the spelling here

  return axios
    .post(AUTH_API_URL+"/upload-deposit", formData)
    .then((res) => res)
    .catch((e) => {
      console.error("Error in nScreenShot():", e);
    });
}


export function raisedTicketList(mobile, tokenId) {
  return fetch(AUTH_API_URL + "/tickets-list", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "allow-access-control-origin": "*",
    },
    body: JSON.stringify({
      mobile: mobile,
      tokenId: tokenId,
      page: 1,
      limit: 10,
    }),
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e, "Error in raisedTicketList()::apis.tsx");
    });
}


export function verifyUserAutoKYC(email, tokenId, doc_number, doc_type, country, state,mobile,clientId,otp) {
    return fetch(AUTH_API_URL + "/verify-Auto-kyc", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "allow-access-control-origin": "*",
      },
      body: JSON.stringify({
        email,
        mobile,
        tokenId,
        doc_number,
        doc_type,
        clientId,
        otp
      }),
    })
      .then((res) => res.json())
      .catch((e) => {
        console.log(e, "Error in");
      });
}

export function generateAadharOtp(email, tokenId, doc_number, doc_type,mobile) {
  return fetch(AUTH_API_URL + "/generate-aadhar-otp", {

    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "allow-access-control-origin": "*",
    },
    body: JSON.stringify({
      email,
      mobile,
      tokenId,
      doc_number,
      doc_type,
    }),
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e, "Error in");
    });
}
export function N_addBinficiary(email, tokenId, account_number, ifsc_code, bank_name, type, updateId) {
  return fetch(AUTH_API_URL + "/set-bank", {
    method: "POST", // Specify the method as POST
    headers: {
      "Content-Type": "application/json", // Set the appropriate headers
    },
    body: JSON.stringify({ email, tokenId, account_number, ifsc_code, bank_name, type, updateId }), // Pass the request body
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e, "Error in N_addBinficiary()::apis.tsx");
    });
}
export function N_UPIVarify(email, tokenId, upi_id, type) {
  return fetch(AUTH_API_URL + "/upi-varify", {
    method: "POST",
     headers: {
      "Content-Type": "application/json", // Set the appropriate headers
    },
    body: JSON.stringify({ email, tokenId, upi_id, type }),
    // Note: Do not set Content-Type header manually when using FormData
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e, "Error in N_addUPI()::apis.tsx");
    });
}

export function N_addUPI(email, tokenId, upi_id, upiData, type, updateId) {
  // const formData = new FormData();
  // formData.append("email", email);
  // formData.append("tokenId", tokenId);
  // formData.append("upi_id", upi_id);
  // formData.append("type", type);


  return fetch(AUTH_API_URL + "/set-upi", {
    method: "POST",
     headers: {
      "Content-Type": "application/json", // Set the appropriate headers
    },
    body: JSON.stringify({ email, tokenId, upi_id, upiData, type, updateId }),
    // Note: Do not set Content-Type header manually when using FormData
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e, "Error in N_addUPI()::apis.tsx");
    });
}

export function N_addUPIWithQRCode(email, tokenId, upi_id, file,upiData, type, updateId) {
  const formData = new FormData();
  formData.append("email", email);
  formData.append("tokenId", tokenId);
  formData.append("upi_id", upi_id);
  formData.append("bank_qr", file, file?.name);
  formData.append("upiData", upiData);
  formData.append("type", type);
  formData.append("updateId", updateId);

  return fetch(AUTH_API_URL + "/set-upi-with-qr", {
    method: "POST",
    body: formData,
    // Note: Do not set Content-Type header manually when using FormData
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e, "Error in N_addUPI()::apis.tsx");
    });
}

export function N_deleteBankUpi(email, tokenId, id, type){
  return fetch(AUTH_API_URL + "/delete-bank-upi", {
    method: "POST",
     headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, tokenId, id, type }),
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e, "Error in N_deleteBankUpi()::apis.tsx");
    });
}



export function N_getUserBankDetails(email, tokenId) {
  return fetch(AUTH_API_URL + "/get-user-bank", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "allow-access-control-origin": "*",
    },
    body: JSON.stringify({email, tokenId}),
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e, "Error in raisedTicketList()::apis.tsx");
    });
}

export function N_getUserUPIDetails(email, tokenId) {
  return fetch(AUTH_API_URL + "/get-user-upi", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "allow-access-control-origin": "*",
    },
    body: JSON.stringify({email, tokenId}),
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e, "Error in raisedTicketList()::apis.tsx");
    });
}
export function getBank() {
  return fetch(AUTH_API_URL+"/get-bank", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    }
  })
    .then((res) => {
      if (!res.ok) {
        throw new Error('Network response was not ok');
      }
      return res.json();
    })
    .catch((e) => {
      console.error(e, "Error in getBank()::apis.tsx");
    });
}

export function conditionsGet(){
  return fetch(AUTH_API_URL+"/conditions_get",{
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    }
  }).then((res) => {
    if (!res.ok) {
      throw new Error('Network response was not ok');
    }
    return res.json();
  }).catch((e) => {
    console.error(e, "Error in conditionsGet()::apis.tsx");
  });
}

export function couponClaim(email, mobile, tokenId,couponCode){
  return fetch(AUTH_API_URL+"/coupon-claim",{
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "allow-access-control-origin": "*",
    },
    body: JSON.stringify({email, mobile, tokenId, couponCode}),

  })
  .then((res) => res.json())
  .catch((e) => {
    console.log(e, "Error in couponClaim()::apis.tsx");
  });
}
