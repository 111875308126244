import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { IoEllipsisVerticalOutline } from "react-icons/io5";
import { HiMiniBars3CenterLeft } from "react-icons/hi2";
import { HiMiniBarsArrowDown } from "react-icons/hi2";

const Header = () => {
  const theme = useSelector((state) => state.theme.theme);
  const { userdata } = useSelector((state) => state.user);
  return (
    <>
      {/* <!-- top navigation bar --> */}
      <nav className="navbar navbar-expand-lg navbar-dark fixed-top header">
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#mobileMenuBottom"
            aria-controls="mobileMenuBottom"
          >
            <HiMiniBars3CenterLeft />
            {/* <span className="navbar-toggler-icon" data-bs-target="#sidebar"></span> */}
          </button>
          <Link
            className="navbar-brand text-uppercase fw-bold ms-4"
            to="/dashboard"
            // style={{ minWidth: "260px" }}
          >
            <img
              src={theme === "dark" ? "images/logo2.svg" : "images/logo.svg"}
              alt=""
              className="logo"
            />
          </Link>

          <div className="d-flex ms-auto">
          <Link
            to="/account"
            className="navbar-toggler_img"          
            aria-label="Toggle navigation"           
          >
            {/* <HiMiniBarsArrowDown /> */}

            <img
              src={userdata?.profile_image?.indexOf('https')>-1?
                userdata?.profile_image:
                userdata?.profile_image
                  ? `data:image/jpeg;base64,${userdata?.profile_image}`
                  : "/images/user.svg"
              }
              alt=""
              style={{
                height: "100%",
                width: "100%",
                objectFit: "cover",
                borderRadius:"50%"
              }}
            />

            {/* <span className="navbar-toggler-icon"></span> */}
          </Link>
          </div>
        
        
        
        </div>
      </nav>
      {/* <!-- top navigation bar --> */}
    </>
  );
};

export default Header;
